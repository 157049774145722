.responsive-truncate-container {
  padding: 10px;
  overflow: auto;
  width: 98%;
  min-width: 400px;
  max-width: 100%;
}

@media screen and (min-width: 1325px) {
  .responsive-truncate-container {
    padding: 10px;
    overflow: auto;
    width: 98%;
    min-width: 1028px;
    max-width: 100%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1324px) {
  .responsive-truncate-container {
    padding: 10px;
    overflow: auto;
    width: 98%;
    min-width: 800px;
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .responsive-truncate-container {
    padding: 10px;
    overflow: auto;
    width: 98%;
    min-width: 600px;
    max-width: 100%;
  }
}

.wrapper-truncate {
  overflow: 'hidden' !important;
  white-space: 'nowrap' !important;
  text-overflow: 'ellipsis' !important;
}
