.sapp-hook-form-tag {
  border-radius: 8px;
  border: 1px solid var(--bs-gray-300);
  padding: 1px 0;
  width: 100%;
  display: flex;
  padding-left: 5px;
  flex-wrap: wrap;
  align-items: center;

  .sapp-tag {
    display: inline-flex;
    align-items: center;
    flex: none;
    box-sizing: border-box;
    max-width: 100%;
    height: 32px;
    margin-top: 2px;
    margin-bottom: 2px;
    line-height: 21px;
    background: rgba(0, 0, 0, 0.06);
    border: 1px solid rgba(5, 5, 5, 0.06);
    border-radius: 6px;
    cursor: default;
    transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    user-select: none;
    margin-inline-end: 4px;
    padding-inline-start: 8px;
    padding-inline-end: 8px;
    font-size: 14px;
    color: var(--bs-gray-700);
    font-weight: 500;

    &-auto-complete {
      flex: 1;
      min-width: 150px;

      &-component {
        width: 100%;
      }
    }

    &-delete {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 7px;
      margin-top: 0.2rem;

      &:hover .ki-duotone {
        color: rgba(0, 0, 0, 0.88);
      }
    }
  }

  .ant-select-selector {
    border: none !important;
  }

  .ant-select-selection-placeholder {
    color: #a1a5b7;
    font-weight: 500;
  }

  &.is-error {
    border: 1px solid var(--bs-form-invalid-color);
  }
}

.sapp-h-45px {
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) {
    .ant-select-selector {
      height: 41.4px;
    }
    .ant-select-selection-search-input {
      height: 41.4px;
      line-height: 41.4px !important;
    }
    .ant-select-selection-placeholder {
      line-height: 41.4px;
    }
  }
}

.sapp-hook-form-tag .sapp-min-w--tag {
  min-width: min-content !important;
}

.sapp-arrow-icon-tag {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  &_spinner {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: var(--bs-gray-700);
    & > div {
      width: 100%;
      height: 100%;
    }
    & .spinner-border {
      border-width: 1px !important;
    }
  }
}
